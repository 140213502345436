
@import "../../../style/Global.scss";

@media (min-width: 280px) {
    .live-logo {
        top: 30px;
        left: 80px;
        position: absolute;
    }

    .live {
        font-weight: bold;
        z-index: 300;

        .circle {
            font-size: 12px;
            margin-left: 5px;
            margin-bottom: 1px;
            color: $ronneby-live-light;
            -webkit-animation-name: change_color;
            -moz-animation-name: change_color;
            -ms-animation-name: change_color;
            animation-name: change_color;
            -webkit-animation-duration: 1s;
            -moz-animation-duration: 1s;
            -ms-animation-duration: 1s;
            animation-duration: 1s;
            -webkit-animation-iteration-count: infinite;
            -moz-animation-iteration-count: infinite;
            -ms-animation-iteration-count: infinite;
            animation-iteration-count: infinite;
            -webkit-animation-direction: alternate;
            -moz-animation-direction: alternate;
            -ms-animation-direction: alternate;
            animation-direction: alternate;
            -webkit-animation-timing-function: ease-in-out;
            -moz-animation-timing-function: ease-in-out;
            -ms-animation-timing-function: ease-in-out;
            animation-timing-function: ease-in-out;

            &.always-available {
                color: $ronneby-marker-dark-green-2;
                -webkit-animation-name: change_color_green;
                -moz-animation-name: change_color_green;
                -ms-animation-name: change_color_green;
                animation-name: change_color_green;
            }
        }

        @-webkit-keyframes change_color {
            to {
                color: $ronneby-live-dark;
            }
        }

        @-moz-keyframes change_color {
            to {
                color: $ronneby-live-dark;
            }
        }

        @-ms-keyframes change_color {
            to {
                color: $ronneby-live-dark;
            }
        }

        @keyframes change_color {
            to {
                color: $ronneby-live-dark;
            }
        }

        @-webkit-keyframes change_color_green {
            to {
                color: darken($ronneby-marker-dark-green-2, 10%);
            }
        }

        @-moz-keyframes change_color_green {
            to {
                color: darken($ronneby-marker-dark-green-2, 10%);
            }
        }

        @-ms-keyframes change_color_green {
            to {
                color: darken($ronneby-marker-dark-green-2, 10%);
            }
        }

        @keyframes change_color_green {
            to {
                color: darken($ronneby-marker-dark-green-2, 10%);
            }
        }
    }
}

@media (min-width: 960px) {
    .live-logo {
        top: calc(#{$nav-height} + 10px);
        left: 25px;
    }
}