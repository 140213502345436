.olofstrom {
  @import "../style/ThemeOlofstrom.scss";
  @import "./App.scss";

  .cookie-consent-container {
    background: $ronneby-blue-6 !important;

    .cookie-consent-button {
      background: $ronneby-blue-2 !important;
    }
  }
}

.ronneby {
  @import "../style/ThemeRonneby.scss";
  @import "./App.scss";

}