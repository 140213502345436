.olofstrom {
    @import "../style/ThemeOlofstrom.scss";
    @import "./NotFound.scss";


}

.ronneby {
    @import "../style/ThemeRonneby.scss";
    @import "./NotFound.scss";


}