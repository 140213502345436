.olofstrom {
    @import "../../../style/ThemeOlofstrom.scss";
    @import "./Marker.scss";


}

.ronneby {
    @import "../../../style/ThemeRonneby.scss";
    @import "./Marker.scss";
}