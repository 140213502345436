
@import "../../style/Global.scss";

    .eventlist-container{
        margin-top: 0px;
        margin-bottom: 20px;

        h4 {
            margin: 0 auto;
            padding: 30px;
        }
    }

    .eventlist-button-container{
        margin: 30px 0px 100px 0px;

        .navigation-option {
            text-align: center;

            .back-to-top-button {
                overflow: hidden;
                font-weight: 400;
                min-width: 300px;
                border-radius: 20px;
                text-transform: none;
                transition: none;

                .MuiButton-label {
                    font-family: $font;
                    font-size: 14px;
                    white-space: pre;
                }
            }
            *:focus {
                outline: none;
            }

            .blue {
                color: $ronneby-white;
                background-color: $ronneby-blue-primary;
                border: 1px solid $ronneby-white;
                &:hover {
                    background-color: $button-active-color;
                }
            }
        }
    }

    .event-tile-container {
        padding: 30px 0px 0px 0px;

        a {
            &:hover {
                text-decoration: none;
            }
        }

        .event-tile {
            background-color: $ronneby-white;
            min-height: 200px;
            color: $ronneby-blue-1;
            border-radius: 10px;
            @include box-shadow(2px, 2px, 5px, 0, rgba(0, 0, 0, 0.1));
            padding: 20px;
            cursor: pointer;

            .event-date-container {
                margin-bottom: 5px;
                color: $ronneby-blue-3;
                font-weight: bold;
                div {
                    display: inline;
                }

                .event-date {
                    margin-right: 20px;
                    color: $ronneby-blue-primary;
                    font-weight: bold;
                }
            }
            .event-title {
                height: 48px;
                margin-bottom: 20px;
                overflow: hidden;
            }

            .event-image {
                width: 100%;
                height: 180px;
                overflow: hidden;
                position: relative;
                border-radius: 10px;

                img {
                    position: absolute;
                    left: -1000%;
                    right: -1000%;
                    top: -1000%;
                    bottom: -1000%;
                    margin: auto;
                    min-width: 100%;
                    height: auto;
                }
            }
        }
    }

    .event-details-wrapper {
        color: $primary-text-color;
        background-color: $ronneby-white;
        min-height: 200px;
        border-radius: 10px;
        @include box-shadow(2px, 2px, 5px, 0, rgba(0, 0, 0, 0.1));
        padding: 20px;
        margin-top: 20px;
        margin-bottom: 20px;

        .event-date-container {
            margin-bottom: 20px;
            color: $ronneby-blue-3;

            div {
                display: inline;
            }

            .event-date {
                margin-right: 20px;
                font-size: 16px;
                font-weight: bold;
            }
        }

        .event-title {
            font-size: 20px;
        }

        .event-image {
            width: 100%;
            height: 180px;
            overflow: hidden;
            position: relative;
            border-radius: 10px;
            margin: 20px 0px;

            img {
                position: relative;
                margin: auto;
                min-width: 100%;
                border-radius: 10px;
                height: 100%;
                object-fit: cover;
            }
        }

        .event-main{

            .event-ingress {
                margin-top: 0px;
                margin-bottom: 30px;
                font-size: 16px;
                font-weight: bold;
                color: $ronneby-black;
                }
        
                .event-description{
                font-size: 16px;
                margin-bottom: 30px;
                color: $ronneby-black;
                font-weight: 400;
                }
        }

        .event-tile{

            .sub-title{
                margin:30px 0px 10px;

                &.main {
                    margin: 0px 0px 10px;
                }
            }

            .event-details-title{
                margin: 0px 0px 2px;
            }

            .event-details{
                margin: 0px 0px 10px;

                .contact_info_link {
                    color: $primary-text-color;
                }

                .hide {
                    display: none;
                }

                .inline {
                    display: inline;
                }
            }

                .breakline { 
                    white-space: pre-wrap;      /* CSS3 */   
                    white-space: -moz-pre-wrap; /* Firefox */    
                    white-space: -pre-wrap;     /* Opera <7 */   
                    white-space: -o-pre-wrap;   /* Opera 7 */    
                    word-wrap: break-word;      /* IE */
                }

                .booking-link {
                    background-color: $ronneby-green;
                    border-radius: 25px;
                    width: 100%;
                    margin: 30px auto 10px;
                    display: flex;
                    color: $ronneby-white;
                    text-decoration: none;
                    text-transform: none;
                    outline: none;
            
                    &:hover{
                        background-color: $ronneby-light-green;
                    }
                }
        }

        .day-picker-btn{
            background: none;
            outline: none;
            border: none;
            padding: 0;
            margin: 5px 0px;

            .day-picker-btn-text {
                border-bottom: 1px solid black;
            }
        }

        .css-f1hklk-MuiGrid-root>.MuiGrid-item {
            padding-top:0;

            hr {
                border-color: $ronneby-blue-6;
            }
        }

        .event-webpage-link{
            color: $ronneby-black;
        }
    }


@media (min-width: 576px) {
    .event-tile-container {
        padding: 20px;
    }
    .event-details-wrapper {
        margin: 20px auto;
        padding: 50px;

        .event-date-container {
            margin-bottom: 20px;

            .event-date {
                font-size: 20px;
            }
        }

        .event-title {
            margin-bottom: 50px;
            font-size: 35px;
        }

        .event-tile{
            padding: 16px 50px 0px 0px;
        }

        .event-main {
            padding: 16px 50px 0px 0px;
        
                .event-description{
                margin-bottom: 30px;
                }
        }

        .event-image {
            height: 300px;
        }
        
    }
    .eventlist-container{
        margin-top: 0px;
    }
}

@media(min-width: 960px) {

    .eventlist-button-container{
        margin: 0px 100px 50px 0px;

        .navigation-option {
            text-align: end;
        }
    }

    .event-details-wrapper {
        .event-image {
            height: auto;

            img {
                height: auto;
            }
        }
        .event-tile{
            padding: 16px 50px 0px 16px;
            
            .sub-title {
                margin:30px 0px 10px;

                &.main {
                    margin: 0px 0px 10px;
                }
            }
        }
    }

    

}
