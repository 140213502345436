@import "../../style/Global.scss";
.breadcrumbs-wrapper {
    margin-top: 80px;
    height: 20px;
    font-size: 14px;
    display: flex;
    overflow-y: hidden;
    overflow-x: scroll;
    // firefox support 
    scrollbar-width: none;
    
    span {
        color: $ronneby-blue-primary;
    
        &:hover {
            cursor: default;
        }
    }

    svg {
        height: 20px;

    }

    &::-webkit-scrollbar {
        display: none;
    }

}

.breadcrumbs-link {
    color: $ronneby-blue-primary;
    flex: none;

    &:hover {
        color:  $ronneby-blue-primary;
        text-decoration: none;
    }
}

.breadcrumbs-icon {
    color: $ronneby-blue-primary;
    margin-left: 12px;
    margin-right: 12px;
}

span {
    &.breadcrumb-last {
        white-space: nowrap;
        padding-right: 20px;
        font-weight: 700;
    
        &:after {
            content: '';
            position: absolute;
            right: 0;
            width: 30%;
            height: 20px;
            background: -webkit-linear-gradient(left, rgba(255,255,255,0) 70%, $ronneby-background-color 90%, $ronneby-background-color 100%);
            pointer-events: none;
        }
    }
}

@media (min-width: 960px) {
    .breadcrumbs-wrapper { 
        margin-top: 30px;
    }
}

