.filter-panel {
    position: fixed;
    bottom: 120px;
    display: flex;
    align-items: center;
    z-index: 500;
    max-height: 70vh;
    flex-direction: column-reverse;

    .filter-container {
        height: calc(70vh - 100px);
        overflow-y: scroll;
        // Firefox-support
        // scrollbar-width: thin;
    }

    .filter-button-container {
        height: 70px;
    }

    .filter-panel-container {
        justify-content: flex-start;
        align-items: flex-start;
        margin-left: 0px;
        margin-top: 0px;
        padding: 0px 5px 20px 5px;
        border-radius: 12px;
        background-color: $ronneby-blue-primary;
        box-shadow: 0px 3px 6px $ronneby-shadow-color;
        padding: 0;

        .list-container {
            padding: 5px 8px 10px 20px;

            .title-divider {
                background-color: $ronneby-white;
                margin: 20px 0px 30px;
            }

            .category-items {

                .category-items-title {
                    color: $ronneby-white;
                    font-weight: normal;
                    display: inline;
                    margin: 0px 10px;
                }

                .check-circle-icon {
                    margin-right: 10px;
                    color: $ronneby-white;

                    &.circle-border {
                        border: 1px solid $ronneby-white;
                        border-radius: 25px;
                        color: $ronneby-blue-primary;
                    }
                }

                &:hover {
                    cursor: pointer;
                }
            }

            .filter-toggle-icon {
                color: $ronneby-white;
                background-color: $ronneby-blue-primary;
                box-shadow: none;
                min-width: 5px;
                height: 25px;
                float: right;
                margin: 10px 0px 0px;
                padding: 10px 7px;
                outline: none;

                &.back {
                    float: left;
                }
            }

            .filter-panel-switch {
                float: left;
                margin-top: 10px;
            }

            .switch-label {
                float: left;
                margin-top: 10px;
                padding: 8px 0px;
                color: $ronneby-white;
                font-family: $font;
                font-size: 14px;
            }

            .filter-title {
                color: $ronneby-white;
                text-align: center;
                font-weight: bold;
                margin: 15px 0px 0px;
            }

            .filter-description {
                color: $ronneby-white;
                text-align: center;
                font-weight: normal;
                margin: 5px 0px 0px;
                font-size: 14px;
            }

            .filter-panel-item {
                display: flex;
                justify-content: center;
                align-items: flex-start;
                height: 115px;

                .filter-panel-button {
                    color: $ronneby-blue-primary;
                    background-color: $ronneby-white;
                    font-weight: 400;
                    border-radius: 15px;
                    text-transform: none;
                }

                *:focus {
                    outline: none;
                }

                .filter-button {
                    margin-top: 7px;
                    display: flex;
                    align-items: center;
                    flex-direction: column;
                    cursor: pointer;
                    color: unset;
                    text-transform: none;
                    padding: 10px 0px 15px;

                    .MuiButton-label {
                        font-family: $font;
                        flex-direction: inherit;
                    }

                    .filter-button-icon-container {
                        line-height: 1.2;

                        .filter-button-icon {
                            background-color: $ronneby-white;
                            box-shadow: 0 3px 6px $ronneby-shadow-color;
                            border-radius: 100%;
                            width: 60px;
                            height: 60px;
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            margin-left: auto;
                            margin-right: auto;

                            .icon {
                                font-size: 24px;
                                color: $ronneby-marker-grey;

                                &.dark-blue {
                                    color: $ronneby-marker-dark-blue;
                                }

                                &.light-blue {
                                    color: $ronneby-marker-light-blue;
                                }

                                &.light-blue-2 {
                                    color: $ronneby-marker-light-blue-2;
                                }

                                &.dark-green {
                                    color: $ronneby-marker-dark-green;
                                }

                                &.dark-green-2 {
                                    color: $ronneby-marker-dark-green-2;
                                }

                                &.light-green {
                                    color: $ronneby-marker-light-green;
                                }

                                &.red {
                                    color: $ronneby-marker-red;
                                }

                                &.yellow {
                                    color: $ronneby-marker-yellow;
                                }

                                &.grey {
                                    color: $ronneby-marker-grey;
                                }

                                &.dark-brown {
                                    color: $ronneby-marker-dark-brown;
                                }

                                &.brown {
                                    color: $ronneby-marker-brown;
                                }

                                &.orange {
                                    color: $ronneby-marker-orange;
                                }

                                &.pink {
                                    color: $ronneby-marker-pink;
                                }

                                &.dark-pink {
                                    color: $ronneby-marker-dark-pink;
                                }

                                &.purple {
                                    color: $ronneby-marker-purple;
                                }

                                &.turquise {
                                    color: $ronneby-marker-turquise;
                                }
                            }
                        }
                    }

                    &.active {
                        .filter-button-icon-container {
                            .filter-button-icon {
                                background-color: $ronneby-blue-1;

                                .icon {
                                    color: $ronneby-white;
                                }
                            }
                        }
                    }

                    &.selected {
                        background-color: $ronneby-marker-dark-blue;
                        border-radius: 10px;
                        min-width: 110px;
                        min-height: 200px;
                        z-index: 1300;

                        .filter-button-close {
                            color: $ronneby-white;
                            position: absolute;
                            display: flex;
                            right: 0;
                            margin-right: 10px;
                        }

                        .filter-button-icon-container {
                            .filter-button-icon {
                                background-color: $ronneby-blue-1;

                                .icon {
                                    color: $ronneby-white;
                                }
                            }
                        }

                        >span:first-child {
                            justify-content: space-between;
                            min-height: inherit;
                        }
                    }

                    .filter-button-label {
                        font-family: $font;
                        margin-top: 5px;
                        font-size: 14px;
                        font-weight: 400;
                        color: $ronneby-white;
                        word-wrap: break-word;
                        overflow-wrap: break-word;
                        width: 100%;
                        line-height: 1.2;
                        padding: 0 2px;
                        display: inline-block;
                    }

                    .sub-menu-options {
                        z-index: 1300;
                        width: 100%;
                        bottom: 0;

                        .sub-menu-divider {
                            border: 1px solid $ronneby-white;
                            border-top: none;
                            margin: 10px;
                        }

                        .sub-menu-option-link {
                            display: flex;
                            justify-content: center;
                            overflow: hidden;
                            font-weight: 400;
                            border-radius: 20px;
                            padding: 5px 20px;
                            text-decoration: none;
                            margin: 0px 15px;
                            align-items: baseline;
                        }

                        *:focus {
                            outline: none;
                        }

                        .white {
                            color: $ronneby-white;

                            &:hover {
                                background-color: $ronneby-blue-4;
                                color: $ronneby-white;
                                border: none;
                            }
                        }
                    }
                }

            }
        }

        .filter-panel-options-container {
            margin: auto;
            padding: 10px;
            display: flex;
            justify-content: center;
            align-items: center;
            height: 70px;

            .filter-panel-option {
                display: flex;
                margin-bottom: 10px;
                text-align: center;

                .filter-panel-option-button {
                    overflow: hidden;
                    font-weight: 400;
                    border-radius: 20px;
                    width: 90%;
                    text-transform: none;
                    transition: none;
                    padding: 5px 0;
                    margin: auto;

                    .MuiButton-label {
                        font-family: $font;
                        font-size: 14px;
                        white-space: pre;
                    }
                }

                *:focus {
                    outline: none;
                }

                .white {
                    color: $ronneby-blue-primary;
                    background-color: $ronneby-white;

                    &:hover {
                        background-color: $ronneby-blue-4;
                        color: $ronneby-white;
                        border: none;
                    }
                }

                .blue {
                    color: $ronneby-white;
                    background-color: $ronneby-blue-primary;
                    border: 1px solid $ronneby-white;

                    &:hover {
                        background-color: $button-active-color;
                    }
                }
            }
        }
    }
}

.filter-toggle {
    width: 80%;
    position: fixed;
    bottom: 0px;
    display: flex;
    justify-content: center;
    align-items: center;

    .filter-toggle-container {
        justify-content: center;
        align-items: center;
        margin-left: 0px;
        margin-top: 0px;

        .filter-toggle-item {
            display: flex;
            justify-content: center;
            align-items: center;
            margin-left: auto;
            margin-right: auto;

            .filter-toggle-button {
                color: $ronneby-blue-primary;
                background-color: $ronneby-white;
                box-shadow: 0px 3px 6px $ronneby-shadow-color;
                font-weight: 400;
                border-radius: 25px;
                flex-direction: column;
                text-transform: none;
                padding: 5px;
                min-width: 150px;
                line-height: 1.5;
                font-size: 14px;

                span {
                    font-family: $font;
                    flex-direction: inherit;
                }
            }

            *:focus {
                outline: none;
            }
        }
    }
}

::-webkit-scrollbar {
    height: 0;
}

@media (min-width: 0px) {
    .background-container {
        background: $ronneby-blue-6;
        /* Fallback for older browsers without RGBA-support */
        background: rgba($ronneby-blue-6, 0.5);
        position: absolute;
        width: 100%;
        height: 100vh;
        z-index: 400;
        top: 0;
    }

    .filter-panel {
        width: calc(100% - 20px);
        position: fixed;
        bottom: 120px;
        margin-left: 10px;
        margin-right: 10px;

        .filter-panel-container {
            overflow: auto;
            margin: 0px 10px;
            overflow-x: hidden;
            overflow-y: hidden;
        }

        .filter-button-container {
            height: 100px;
        }
    }

    .filter-toggle {
        width: 150px;
        margin-left: -75px;
        padding-top: 16px;
        padding-bottom: 16px;
        position: fixed;
        bottom: 25px;
        left: 50%;
        right: 50%;
        z-index: 500;
    }
}

// @media (max-height: 512px) {
//     .filter-panel {
//         .filter-panel-container {
//             max-height: 160px;
//             overflow: auto;

//             .list-container {
//                 max-height: 100%;
//             }
//         }
//     }
// }

@media (min-width: 512px) {
    .filter-panel {
        .filter-button-container {
            height: 110px;
        }
    }
}

@media (min-width: 960px) {
    .background-container {
        display: none;
    }

    .filter-panel {
        width: 850px;
        margin-left: -425px;
        position: fixed;
        bottom: 110px;
        left: 50%;
        right: 50%;
        z-index: 200;

        .filter-button-container {
            height: 70px;
        }

        .filter-panel-container {
            margin: 0px;

            .list-container {
                .filter-panel-item {
                    .filter-button {
                        &:hover {
                            .filter-button-icon-container {
                                .filter-button-icon {
                                    background-color: $ronneby-blue-1;

                                    .icon {
                                        color: $ronneby-white;
                                    }
                                }
                            }
                        }
                    }
                }

                .category-items {
                    .category-items-title {
                        color: $ronneby-white;
                        font-weight: normal;
                        display: inline;
                        margin: 0px 70px;
                    }
                }
            }
        }
    }

    .filter-toggle {
        position: fixed;
        bottom: 25px;
        left: 50%;
        right: 50%;
    }
}