.olofstrom {
    @import "./../../../style/ThemeOlofstrom.scss";
    @import "./GoogleMaps.scss";

}

.ronneby {
    @import "./../../../style/ThemeRonneby.scss";
    @import "./GoogleMaps.scss";

}