.olofstrom {
    @import "./../../style/ThemeOlofstrom.scss";
    @import "./Error.scss";


}

.ronneby {
    @import "./../../style/ThemeRonneby.scss";
    @import "./Error.scss";


}