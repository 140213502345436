
@import "../../style/Global.scss";

.event-filter {
    width: 100%;
    height: 70px;
    margin-top: 1px;
    overflow-x: visible;
    padding: 25px;
    box-shadow: none !important;
    background-color: transparent !important;

    .select {
        background-color: $ronneby-white;
        border-radius: 10px;
        border: 1px solid $ronneby-blue-5;

        &.date {
            width: 95%;
            margin: 20px auto 10px auto;
    
            .MuiDayPicker-root {
                border-radius: 10px;
                margin: 0 auto !important;
                
                .MuiPickersCalendar-root {
                    min-height: 220px;
                    overflow: hidden;
                }
            }
        }
    
        &.category {
            width: 95%;
            margin: 20px auto 10px auto;
    
            hr.solid {
                padding-bottom: 10px;
            }
    
            .filter-btn {
                padding: 10px 20px;
            }
    
            .category-picker-item {
                width: 100%;
                padding: 10px 20px;
                cursor: pointer;
    
                &.active {
                    background-color: $ronneby-blue-6;
                }
    
                &:focus {
                    outline: none;
                }
    
                &:focus-visible {
                    outline: 2px solid $ronneby-black;
                }
            }
        }

        &.closed{
            height: 42px;
        }

        &.open {
            box-shadow: 0px 3px 6px $ronneby-shadow-color;
        } 

        >button:first-child {
            color: $ronneby-black;
            text-transform: none;
            border: none;
            width: 100%;
            height: 42px;
            border-radius: 10px;
            font-size: 16px;
            font-weight: 400;
            font-family: $font;

            &:focus {
                outline: none;
            }

            .chevron-left {
                position: absolute;
                left: 10px;
                font-size: 14px;
            }

            .chevron-right {
                position: absolute;
                right: 10px;
                font-size: 14px;
            }

            .chevron-down{
                position: absolute;
                right: 10px;
                font-size: 14px;
                transition: transform 1s;

                &.open {
                    transform: rotate(180deg);
                }
            }
        }

        hr.solid {
            width: 90%;
            border-top: 1px solid $ronneby-blue-5;
            margin: 0px auto;
        } 
    }

    .search-container {
        position: relative;
        margin-top: 0px;

        .search-item {
            display: flex;
            justify-content: right;

            .search-field {
                width: 75%;
                height: 50px;
                display: inline-flex;
    
                .search-icon {
                    margin: 14px;
                    font-size: 20px;
                    color: $ronneby-blue-2;
                }
    
                .search-input {
                    outline: none;
                    border: none;
                    height: 50px;
                    width: 100%;
                    padding: 13px 13px 13px 0;
                    
                    >div {
                        >fieldset {
                            border-color: transparent;
                        }
                        >input {
                            padding: 0px;
                            color: $ronneby-blue-primary;
                            font-weight: 400;
                            font-family: $font;
                        }
                    }
    
                    &:focus {
                        outline: none;
                    }
                }
            } 

            .search-btn {
                width: 22%;
                margin: 0 0 10px auto;
            } 
        }
    }

    .filter-menu-container {
        display: block;
        width: 90%;
        height: 80%;
        position: fixed;
        z-index: 1100;
        bottom: 90px;
        left: 5%;
        margin-top: 0;
        margin-left: 0;
        overflow: hidden;
        border-radius: 10px;

        .filter-menu {
            width: 100%;
            height: 100%;
            background-color: $ronneby-white;
            justify-content: center;
            overflow-y: scroll;
            padding-left: 8px;
            // firefox support 
            scrollbar-width: thin;

            .picked-categories {
                width: 95%;
                margin: 0 auto;
                display: flex;
                flex-wrap: wrap;

                .filter-item {
                    background-color: $ronneby-blue-4;
                    border-radius: 10px;
                    padding: 5px 10px 5px 10px;
                    margin: 10px 10px 0 0;
        
                    .delete {
                        margin: 0 0 0 10px;
                    }
                }
            }

            .filter-menu-btn {
                display: flex;
                margin: 20px auto 30px auto !important;
                text-transform: none;
                font-weight: normal;
                font-family: inherit;
            }
        }
    }

    .filter-btn-container {
        width: 100%;
        padding: 0px 20px 10px 20px;
        display: flex;
        justify-content: center;
        position: relative;
        z-index: 1100;
        align-items: flex-end;
        
        .filter-btn {
            width: 140px;
            height: 42px;
            border-radius: 20px;
            justify-self: center;
            margin: 10px auto 0px auto;

            &.icon-btn {
                position: fixed;
                z-index: 900;
                bottom: 20px;
                justify-self: center;
                margin: 10px auto 0px auto;
                width: 100px;
                height: 50px;
                border: none;
                border-radius: 25px ;
                box-shadow: 0px 3px 6px $ronneby-shadow-color;
                color: $ronneby-blue-primary;
                background-color: $ronneby-white !important;
                flex-direction: column;
                line-height: 1.5;
            
                .icon {
                    font-size: 16px;
                }
            
                span {
                    text-transform: none;
                    font-size: 14px;
                    font-weight: 400;
                    font-family: $font;
                    flex-direction: inherit;
                }
            
                &:focus {
                    outline: none;
                }
            }
        }
    }

    .border-btn {
        border-radius: 20px;
        border: 1px solid $ronneby-blue-primary;
        background-color: $ronneby-white;
        color: $ronneby-blue-primary;
        text-transform: none;
        font-size: 16px;
        font-family: $font;
    }

    .primary-btn {
        height: 50px;
        background-color: $ronneby-blue-primary;
        border-radius: 10px;
        border: none;
        color: $ronneby-white;
        font-weight: bold;
    
        &:hover {
            background-color: $ronneby-blue-primary;
        }
        &:focus {
            outline: none
        }
    }
}

@media (max-height: 512px){
    .event-filter {
        .filter-menu-container {
            max-height: 160px;
        }
    }
}


@media(min-width: 960px) {
    .event-filter {
        height: 100px;
        background-color: $ronneby-white !important;
        border: 1px solid $ronneby-blue-6;
        border-radius: none;
        display: inline-flex;

        .select-container {
            display: flex;

            .select {
                position: relative;
                z-index: 1100;

                &.date {
                    width: 330px;
                    margin: 0 10px 0 20px;
                    display: inline-block;

                    .MuiDayPicker-root {
                        width: 320px;
                        
                    }
                }

                &.category {
                    width: 35%;
                    max-width: 305px;
                    margin: 0 0 0 10px;
                    display: inline-block;
                }
            }
        }

        .search-container {
            display: flex;
            justify-content: right;

            .search-field {
                width: 80% !important;
                max-width: 558px;
                height: 42px !important;
                background-color: $ronneby-background-color;
                margin-right: 0;
                margin-left: auto;

                .search-icon {
                    margin: 10px !important;
                }

                .search-input {
                    height: 42px;
                    width: 100%;
                    padding: 10px !important;
                }
            }

            .search-btn {
                min-width: 50px;
                width: 18%;
                max-width: 100px;
                height: 42px;
                margin: 0 20px 10px 20px !important;
            }
        }
    } 

}
