@import "../../../style/Global.scss";

@media (min-width: 280px) {
    .MuiDayPicker-root {
        max-width: 250px;
    }

    .MuiPickerView-root {
        margin: 0px !important;
    }

    .MuiPickersCalendarHeader-root {
        padding-left: 0px;
        padding-right: 0px;
        font-weight: 700 !important;
        
        .MuiPickersCalendarHeader-label {
            font-family: $font;
            font-weight: 400;
            pointer-events: none;
            
            .MuiPickersCalendarHeader-yearSelectionSwitcher { 
                display: none;
            }
        }
    }

    .MuiPickersCalendar-weekDayLabel {
        margin: 0px 0px;
        font-weight: 700 !important;;
    }
    .MuiPickersDay-dayWithMargin {
        margin: 0px 0px;
        font-weight: 700 !important;;
    }

    .MuiPickersDay-root.Mui-selected {
        background-color: $ronneby-blue-primary !important;
        color: $ronneby-white !important;
        font-weight: 700 !important;
    }

    .MuiPickersDay-root:focus.Mui-selected {
        background-color: $ronneby-blue-primary;
        font-weight: 700 !important;
    }

    .MuiPickersYear-yearButton.Mui-selected {
        background-color: $ronneby-blue-primary !important;
        outline: none !important;
        font-weight: 700 !important;
    }

    .css-w460v-MuiButtonBase-root-MuiIconButton-root{
        outline: none !important;
    }
    .css-1eto09t-MuiButtonBase-root-MuiIconButton-root {
        outline: none !important;
    }
    .css-qlovab-MuiButtonBase-root-MuiIconButton-root {
        outline: none !important;
    }

    .link{
        text-decoration: none !important;
    }


    .upcoming-events{
        background-color: $ronneby-blue-5 !important;
        color: $primary-text-color !important;

        &:focus{
            background-color: $ronneby-blue-primary !important;
            color: $ronneby-white !important;
        }
    }
    .outdated {
        color: rgba(0, 0, 0, 0.6) !important;
    }

    .date-list-container{

        .date-title{
            margin: 20px 0px;
        }
        .button-container{
            text-align: center;

            .button{
                background-color: transparent;
                color: $ronneby-black;
                border-radius: 25px;
                outline: none;
                min-width: 50px;
    
                &:disabled{
                    color: $ronneby-blue-5;
                }
            }
        }
    }
}

@media (min-width: 375px) {
    .MuiDayPicker-root {
        max-width: 400px !important;
    }

    .MuiPickersCalendarHeader-root {
        padding-left: 0px;
        padding-right: 0px;
    }

    .MuiPickersCalendar-weekDayLabel {
        margin: 0px 2px !important;
    }
    .MuiPickersDay-dayWithMargin {
        margin: 0px 2px !important;
    }

}

@media (min-width: 576px) {
    .MuiDayPicker-root {
        max-width: 400px;
    }

    .MuiPickersCalendarHeader-root {
        padding-left: 0px;
        padding-right: 0px;
    }

    .MuiPickersCalendar-weekDayLabel {
        margin: 0px 5px !important;
    }
    .MuiPickersDay-dayWithMargin {
        margin: 0px 5px !important;
    }
}

@media (min-width: 960px) {
    .MuiPickersCalendarHeader-root {
        padding-left: 24px;
        padding-right: 24px;
    }
}