.olofstrom {
    @import "../../../src/style/ThemeOlofstrom.scss";
    @import "./AboutLive.scss";


}

.ronneby {
    @import "../../../src/style/ThemeRonneby.scss";
    @import "./AboutLive.scss";


}