
@import "../../style/Global.scss";

.about-wrapper {
    color: $primary-text-color;
    background-color: $ronneby-white;
    min-height: 200px;
    border-radius: 10px;
    @include box-shadow(2px, 2px, 5px, 0, rgba(0, 0, 0, 0.1));
    padding: 20px;
    margin-top: 20px;
    margin-bottom: 20px;

    .about-title {
        font-size: 20px;
    }

    .about-image {
        width: 100%;
        height: 180px;
        overflow: hidden;
        position: relative;
        border-radius: 10px;
        margin: 20px 0px 40px;

        img {
            position: relative;
            margin: auto;
            min-width: 100%;
            border-radius: 10px;
            height: 100%;
            max-height: 360px;
            object-fit: cover;
        }
    }

    .about-sub-title{
        font-size: 20px;
    }

    .about-main{

        .about-ingress {
            margin-top: 0px;
            margin-bottom: 30px;
            font-size: 16px;
            font-weight: bold;
            color: $ronneby-black;
            }
    
            .about-description{
            font-size: 16px;
            margin-bottom: 30px;
            color: $ronneby-black;
            font-weight: 400;
            }
    }

    .about-tile{

        .sub-title{
            margin:32px 0px 0px;

            &.main {
                margin: 0px;
            }
        }

        .about-details-title{
            margin: 10px 0px 2px;
        }

        .about-details{
            margin: 0px;

            .contact_info_link {
                color: $primary-text-color;
            }
        }

            .breakline { 
                white-space: pre-wrap;      /* CSS3 */   
                white-space: -moz-pre-wrap; /* Firefox */    
                white-space: -pre-wrap;     /* Opera <7 */   
                white-space: -o-pre-wrap;   /* Opera 7 */    
                word-wrap: break-word;      /* IE */
            }
    }

    .css-f1hklk-MuiGrid-root>.MuiGrid-item {
        padding-top:0;

        hr {
            border-color: $ronneby-blue-6;
        }
    }
}

@media (min-width: 576px) {
    .about-tile-container {
        padding: 20px;
    }
    .about-wrapper {
        margin: 20px auto;
        padding: 50px;

        .about-title {
            margin-bottom: 44px;
            font-size: 35px;
        }

        .about-sub-title{
            margin-bottom: 20px;
            font-size: 28px;
        }

        .about-tile{
            padding: 16px 50px 0px 0px;
        }

        .about-main {
            padding: 16px 50px 0px 0px;
        
                .about-description{
                margin-bottom: 30px;
                }
        }

        .about-image {
            height: 300px;
        }
        
    }
}

@media(min-width: 960px) {

    .about-wrapper {
        .about-image {
            height: auto;

            img {
                height: auto;
            }
        }
        .about-tile{
            padding: 16px 50px 0px 16px;
            
            .sub-title {
                margin:30px 0px 0px;

                &.main {
                    margin: 0px 0px 10px;
                }
            }
        }
    }

    

}