.olofstrom {
    @import "../../../style/ThemeOlofstrom.scss";
    @import "./Live.scss";


}

.ronneby {
    @import "../../../style/ThemeRonneby.scss";
    @import "./Live.scss";


}