.olofstrom {
    @import "../../style/ThemeOlofstrom.scss";
    @import "./Breadcrumbs.scss";


}

.ronneby {
    @import "../../style/ThemeRonneby.scss";
    @import "./Breadcrumbs.scss";


}