.olofstrom {
    @import "../../style/ThemeOlofstrom.scss";
    @import "./Report.scss";

}

.ronneby {
    @import "../../style/ThemeRonneby.scss";
    @import "./Report.scss";

}