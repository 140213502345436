.olofstrom {
    @import "../../../src/style/ThemeOlofstrom.scss";
    @import "./Event.scss";

    .event-date {
        margin-right: 20px !important;
        font-weight: normal !important;
    }

}

.ronneby {
    @import "../../../src/style/ThemeRonneby.scss";
    @import "./Event.scss";


}