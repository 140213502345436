
@import "./Global.scss";

html {
  overflow: overlay;
}
body {
  background-color: $ronneby-background-color !important;

  .overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    z-index: 1100;
    background-color: rgba(0, 0, 0, 0.5);
    overflow: hidden;
  }
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: $ronneby-white;
}

.App-link {
  color: #61dafb;
}

.container {
  margin-top: 100px;
}

h1 {
  color: $ronneby-blue-1;
  font-size: 32px;
  font-weight: bold;
}

h2 {
  color: $ronneby-blue-1;
  font-size: 24px;
  font-weight: bold;
}

h3 {
  color: $ronneby-blue-1;
  font-size: 20px;
  font-weight: bold;
}

h4 {
  color: $ronneby-blue-1;
  font-size: 16px;
  font-weight: bold;
}

h5 {
  color: $ronneby-blue-1;
  font-weight: bold;
}

h6 {
  color: $ronneby-blue-1;
  font-weight: bold;
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  z-index: 1200;
  background-color: rgba(0, 0, 0, 0.5);
  overflow: hidden;
}

.cookie-consent-container {
  background: $ronneby-blue-5 !important;
  z-index: 1600 !important;
  color: $ronneby-black !important;
  font-size: 16px !important;
  align-items: center !important;

  .cookie-consent-button {
    color: $ronneby-white !important;
    background: $ronneby-blue-primary !important;
    border-radius: 25px !important;
    padding: 10px !important;
    width: 150px !important;
    margin: auto 0px;
  }
  .cookie-consent-link {
    color: $primary-text-color;
    text-decoration: underline;
  }
}

@include media-breakpoint-up(lg) {
  .container {
    margin-top: inherit;
  }
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@media (min-width: 767px) {
  .cookie-consent-container {
    border-radius: 0px 40px 0px 0px !important;
    width: 40% !important;
  }
}
