body {
  margin: 0;
  font-family: $font;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

::-webkit-scrollbar-track {
  width: 8px;
}

::-webkit-scrollbar {
  -webkit-appearance: none;
  width: 8px;
  height: 10px;
  
}
::-webkit-scrollbar-thumb {
  border-radius: 4px;
  background-color: #035e8e;
  -webkit-box-shadow: 0 0 1px rgba(255, 255, 255, .5);
}

:root{
  scrollbar-color: #035e8e transparent !important;
  scrollbar-width: thin !important;
}

