@import "../../style/Global.scss";

.olofstrom {
    @import "../../style/ThemeOlofstrom.scss";
    @import "./TopNavigation.scss";
    @import "./MobileNavigation.scss";

    .logo-container {
        img {
            background: rgba(255, 255, 255, 0.1);
            width: 150px !important;
        }
    }

    @media (min-width: 960px) {
        #nav-logo {
            img {
                width: 250px !important;
            }
        }
    }
}

.ronneby {

    @import "../../style/ThemeRonneby.scss";
    @import "./TopNavigation.scss";
    @import "./MobileNavigation.scss";

    #map-container {
        margin-top: -1px !important;
    }
}