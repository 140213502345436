
@import "../style/Global.scss";

.not-found-wrapper {
    color: $primary-text-color;
    background-color: $ronneby-white;
    min-height: 200px;
    border-radius: 10px;
    @include box-shadow(2px, 2px, 5px, 0, rgba(0, 0, 0, 0.1));
    padding: 20px;
    margin-top: 100px;
    margin-bottom: 20px;
    text-align: center;

    >div {
        padding: 50px;

        .back-button {
            width: 250px;
            height: 42px;
            background-color: $ronneby-blue-primary;
            border-radius: 10px;
            box-shadow: 0px 3px 6px $ronneby-shadow-color;
            color: $ronneby-white;
            font-weight: bold;
            text-transform: none;
            margin: 20px 0 0 0;
    
            &:hover {
                background-color: $ronneby-blue-primary;
            }
            &:focus {
                outline: none
            }
        }
    }
}

@media (min-width: 960px) {
    .not-found-wrapper {     
        margin-top: 30px;
    }
}