
@import "../../style/Global.scss";

.error-banner {
    position: fixed;
    background-color: $ronneby-white;
    width: 100%;
    max-width: 100% !important;
    min-height: 200px;
    max-height: 50%;
    top: 25%;
    align-self: center;
    text-align: center;
    padding: 50px;

    .back-button {
        width: 250px;
        height: 42px;
        background-color: $ronneby-blue-primary;
        border-radius: 10px;
        box-shadow: 0px 3px 6px $ronneby-shadow-color;
        color: $ronneby-white;
        font-weight: bold;
        text-transform: none;
        margin: 20px 0 0 0;

        &:hover {
            background-color: $ronneby-blue-primary;
        }
        &:focus {
            outline: none
        }
    }
}
