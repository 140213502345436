@import "~bootstrap/scss/_functions.scss";
@import "~bootstrap/scss/_variables.scss";
@import "~bootstrap/scss/mixins/_breakpoints.scss";

$nav-height: 100px;
$map-height: calc(100vh - #{$nav-height});

@mixin box-shadow($top, $left, $blur, $size, $color) {
    -webkit-box-shadow: $top $left $blur $size $color;
    -moz-box-shadow: $top $left $blur $size $color;
    box-shadow: $top $left $blur $size $color;
}
