
.control {
    position: fixed;
    right: 16px;
    background-color: $ronneby-white;
    width: 50px;
    box-shadow: 0px 3px 6px $ronneby-shadow-color;
    border-radius: 25px;
    border: none;
    min-width: 50px;

    &.zoom {
        bottom: 170px;
        height: 100px;
    
        .plus-control {
            display: flex;
            height: 50px;
            width: 50px;
            border: none;
            border-radius: 25px 25px 0px 0px;
            background-color: $ronneby-white;
            min-width: 50px;
    
            &:focus {
                outline: none;
            }
        }
    
        .minus-control {
            display: flex;
            height: 50px;
            width: 50px;
            border: none;
            border-radius: 0px 0px 25px 25px;
            background-color: $ronneby-white;
            min-width: 50px;

            &:focus {
                outline: none;
            }
        }

        hr.solid {
            border-top: 1px solid  $ronneby-blue-primary;
            width: 90%;
            margin: 0 auto;
        }
    }
    
    &.position {
        bottom: 100px;
        height: 50px;
        border: none;
    
        .position-control {
            display: flex;
            height: 100%;
            width: 100%;
            border: none;
            border-radius: 25px 25px 0px 0px;
            min-width: 50px;
    
            &:focus {
            outline: none;
            pointer-events: all;
            
            }
        }
    }
    
    .control-icon {
        color: $ronneby-blue-primary;
        font-size: 22px;
        width: 100%;
        align-self: center;
        justify-self: center;
        margin: 0 auto;
    }
}

@media (min-width: 960px) {
    .control {
        right: 30px;
    }
}


