.olofstrom {
    @import "./../../../style/ThemeOlofstrom.scss";
    @import "./MapFilter.scss";

    .filter-panel {
        .filter-panel-container {
            .filter-panel-options-container {
                .filter-panel-option .blue {

                    background-color: $ronneby-blue-7 !important;

                    &:hover {
                        background-color: $ronneby-blue-4 !important;
                        color: $ronneby-white !important;
                        border: none;
                    }
                }
            }

            .list-container {
                .filter-panel-item {
                    .filter-button {
                        &.selected {
                            background-color: $ronneby-blue-7 !important;

                            .filter-button-icon-container {
                                .filter-button-icon {
                                    background-color: $ronneby-blue-7 !important;
                                }
                            }
                        }

                        &.active {
                            .filter-button-icon-container {
                                .filter-button-icon {
                                    background-color: $ronneby-blue-7 !important;
                                }
                            }
                        }

                    }

                    .filter-button:hover {
                        .filter-button-icon-container {
                            .filter-button-icon {
                                background-color: $ronneby-blue-7 !important;
                            }
                        }

                    }

                }
            }
        }
    }

    .filter-toggle {
        .filter-toggle-container {
            .filter-toggle-item {
                .filter-toggle-button {
                    color: #ffffff;
                    background-color: $ronneby-blue-7 !important;

                    &:hover {
                        background-color: $ronneby-blue-4 !important;
                        color: $ronneby-white !important;
                        border: none;
                    }
                }
            }
        }
    }
}

.ronneby {
    @import "./../../../style/ThemeRonneby.scss";
    @import "./MapFilter.scss";


}