
@media (min-width: 280px) {
    .report-container {
        margin-top: 10px;
        margin-bottom: 40px;
    }
}

@media (min-width: 960px) {
    .report-container {
        margin-top: 10px;
    }
}
