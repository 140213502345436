.olofstrom {
    @import "./../../../style/ThemeOlofstrom.scss";
    @import "./Loader.scss";


}

.ronneby {
    @import "./../../../style/ThemeRonneby.scss";
    @import "./Loader.scss";


}