.olofstrom {
    @import "../../../src/style/ThemeOlofstrom.scss";
    @import "./EventFilter.scss";

    .primary-btn {
        background-color: $ronneby-blue-2 !important;

        &:hover {
            background-color: lighten($color: $ronneby-blue-2 , $amount: 10) !important;
        }
    }
}

.ronneby {
    @import "../../../src/style/ThemeRonneby.scss";
    @import "./EventFilter.scss";
}