

.share {
    margin-top: 10px;
    align-items: baseline;

    .categories {
        margin: 0px 15px;
        align-items: baseline;
        border-radius: 20px;
        padding: 5px;

        a {
            color: $ronneby-white;
            cursor: pointer;
            font-weight: 400;
            text-decoration: none;
        }
        .icon {
            margin: 0px 6px;
            color: $ronneby-white;
            font-size: 12px;
        }

        &:hover {
            background-color: $ronneby-blue-4;
            color: $ronneby-white;
            border: none;
        }

    }

    a {
        color: $ronneby-blue-1;
        cursor: pointer;
        text-decoration: none;
    }

    .icon {
        margin-left: 6px;
        color: $ronneby-blue-1;
        font-size: 12px;
    }

    .share-menu {
        z-index: 100;
        padding: 15px 0;

        &.infoBox {
            border-radius: 10px;
            position: absolute;
            right: 0;
            background-color: $ronneby-white;
            box-shadow: 0px 3px 6px $ronneby-shadow-color;
            padding: 15px;

            .button-social {
                margin: 0px;
                
            }
        }

        &.categories {
            background-color: $ronneby-blue-1;
            margin: 0px;
            padding: 15px 5px;

            .button-social {
                justify-content: center;
                display: flex;
                margin-right: 0px;
                
                button {
                    background-color: $ronneby-blue-1;

                    .menu-icon {
                        color: $ronneby-white;
                    }
                }
            }
        }

        .button-social {
            justify-content: center;
            display: flex;
            margin-right: 10px;
    
            button {
                background-color: transparent;
                border-radius: 25px;
                min-width: 25px;
                padding: 5px;

                .menu-icon {
                    color: $ronneby-blue-1;
                }

                &:hover {
                    background-color: none;
                }

                &:focus {
                    outline: none;
                }
            }
        }  
    }
}
